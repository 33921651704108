import React, { useState, useEffect } from 'react';
import moment from 'moment';

import api from './api';

import './Client.scss';

const printableDate = (date, format) => moment(date, format).format('D MMM, YYYY');

const failed = <span className="failure">Failed</span>;

const status = (data, key) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  return (key ? data[key] : data) ? 'OK' : failed;
};

const botStatus = (data) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  const messageDate = printableDate(data?.date, 'YYYY-MM-DD');
  const now = printableDate(new Date());
  return messageDate === now ? 'OK' : `Outdated (${messageDate})`;
};

const buildInfo = (data) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  const {date, version} = data;
  const d = printableDate(date, 'DD-MM-YYYY');
  return `${version} (${d})`;
};

const calendarStatus = (data) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  const {updated, records} = data;
  const date = updated ? printableDate(updated, 'MM/DD/YYYY') : 'Never';
  return `${records} records (${date})`;
};

const deploymentStatus = (data) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }
  const {mtime} = data;
  return mtime ? printableDate(mtime) : 'Unknown';
};

const Client = () => {
  const [username, setUsername] = useState();
  const [lastMessage, updateMessage] = useState();
  const [build, updateBuild] = useState();
  const [calendarStats, updateCalendarStats] = useState();
  const [deployment, setDeployment] = useState();

  useEffect(() => {
    (async () => {
      updateBuild(await api('build'));
      setUsername(await api('user'));
      updateMessage(await api('db'));
      updateCalendarStats(await api('cal/status'));
      setDeployment(await api('deployment'));
    })();
  }, []);

  return (
    <>
      <h1>bzmn.xyz</h1>
      <ul className="statuses">
        <li>
          <b>Build</b>
          <span>{buildInfo(build)}</span>
        </li>
        <li>
          <b>Deployment</b>
          <span>{deploymentStatus(deployment)}</span>
        </li>
        <li>
          <b>API</b>
          <span>{status(username, 'username')}</span>
        </li>
        <li>
          <b>Db</b>
          <span>{status(calendarStats)}</span>
        </li>
        <li>
          <b>Bot</b>
          <span>{botStatus(lastMessage, 'date')}</span>
        </li>
        <li>
          <b>iCal</b>
          <span>{calendarStatus(calendarStats)}</span>
        </li>
      </ul>
    </>
  );
};

export default Client;
