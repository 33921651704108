import debug from 'debug';

const api = async (endpoint) => {
  const logger = debug('api');
  try {
    const response = await fetch(`/api/${endpoint}`);
    return response.ok && response.json().catch(() => false);
  } catch (e) {
    logger(`Error fetching data from ${endpoint}: ${e}`);
    return false;
  }
};

export default api;
